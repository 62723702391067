import ProjectModel from "../../store/models/ProjectModel";
import moment from "moment";
import ProjectTechnologyList from "./ProjectTechnologyList";
import ListItem from "../ui/ListItem";

export type ProjectListItemProps = {
  project: ProjectModel;
}

export default function ProjectListItem(props: ProjectListItemProps) {
  return (
    <ListItem
      title={props.project.name}
      content={props.project.short_description}
      date={moment(props.project.started_at).format("MMMM YYYY")}
      image={props.project.logo}
      href={`/projects/${props.project.slug}`}
    >
      <ProjectTechnologyList technologies={props.project.technologies}/>
    </ListItem>
  )
}