export type SubheadingProps = {
  text: string;
}

export default function SectionBreak(props: SubheadingProps) {
  return (
    <div className="relative my-8">
      <div className="absolute inset-0 flex items-center" aria-hidden="true">
        <div className="w-full border-t border-default"/>
        <div className="w-1.5 h-1.5 bg-default rounded-full"/>
      </div>
      <div className="relative flex justify-left text-sm">
        <h3 className="w-48 py-2 text-center border border-2 border-special rounded-lg bg-background font-bold">
          {props.text}
        </h3>
      </div>
    </div>
  );
}