import Marquee from "react-fast-marquee";

export type MarqueeProps = {
  children: any;
}

export default function StyledMarquee(props: MarqueeProps) {
  return (
    <Marquee
      gradientWidth={125}
      gradientColor={[20, 20, 20]} // dark-mode
      // gradientColor={[242, 242, 242]} // light-mode
      pauseOnHover={true}
    >
      {props.children}
    </Marquee>
  )
}