import Link from "next/link";
import {useRouter} from "next/router";
import Image from 'next/image';

export type ListItemProps = {
  title: string;
  content: string;
  date?: string;
  image?: string | null;
  href?: any;
  children?: any;
}

export default function ListItem(props: ListItemProps) {

  const router = useRouter();

  return (
    <div className="flex gap-x-4">
      <div className="basis-2/12 text-center">
        <Image
          src={props.image ? props.image : "/404.png"}
          alt={props.title + "'s image"}
          width={128} height={128}
          className="cursor-pointer"
          onClick={() => {
            if (props.href) router.push(props.href);
          }}
        />
      </div>
      <div className="basis-10/12">
        <div className="flex flex-col md:flex-row justify-between mb-4">
          <div className="text-special">
            {props.href ? (
              <Link href={props.href}><a className="hover:text-special-secondary"><h4>{props.title}</h4></a></Link>
            ) : (
              <>{props.title}</>
            )}
          </div>
          <div className="text-secondary text-sm md:text-base">{props.date}</div>
        </div>
        <div className="text-sm md:text-base md:text-justify mb-4">
          {props.content}
        </div>
        {props.children}
      </div>
    </div>
  )
}