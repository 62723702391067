import classNames from "../../util/tailwind-util";

export type ButtonProps = {
  children: any;
  className?: string;
  onClick?: () => void;
}

export default function Button(props: ButtonProps) {
  return (
    <button
      className={classNames(
        "text-sm md:text-base relative bg-default text-dark-gray px-12 py-5 rounded-2xl text-background duration-200 my-2 hover:bg-special hover:text-default transition ease-in-out hover:scale-105",
        props.className ? props.className : "",
      )}
      onClick={props.onClick}
    >
      {props.children}
    </button>
  );
}