import type {NextPage} from "next"
import {GetServerSideProps} from "next";
import SectionBreak from "../components/ui/SectionBreak";
import StyledMarquee from "../components/ui/StyledMarquee";
import classNames from "../util/tailwind-util";
import {BLUE_GRADIENT, TEXT_GRADIENT} from "../util/gradient";
import ProjectList from "../components/project/ProjectList";
import {getProjects} from "../api/backend";
import ProjectModel from "../store/models/ProjectModel";
import Button from "../components/ui/Button";
import Link from "next/link";
import WideButton from "../components/ui/WideButton";
import {useRouter} from "next/router";

const technologies = [
  {name: "git", href: "https://git-scm.com/"},
  {name: "python", href: "https://www.python.org/"},
  {name: "javascript", href: "https://developer.mozilla.org/en-US/docs/Web/JavaScript"},
  {name: "mysql", href: "https://www.mysql.com/"},
  {name: "docker", href: "https://www.docker.com/"},
  {name: "django", href: "https://www.djangoproject.com/"},
  {name: "java", href: "https://www.java.com/en/"},
  {name: "php", href: "https://www.php.net/"},
  {name: "lua", href: "https://www.lua.org/"},
  {name: "react", href: "https://reactjs.org/"},
  {name: "typescript", href: "https://www.typescriptlang.org/"},
  {name: "postgresql", href: "https://www.postgresql.org/"},
  {name: "nextjs", href: "https://nextjs.org/"},
  {name: "kotlin", href: "https://kotlinlang.org/"},
  {name: "flask", href: "https://flask.palletsprojects.com/en/2.2.x/"},
  {name: "fastapi", href: "https://fastapi.tiangolo.com/"},
  {name: "tailwindcss", href: "https://tailwindcss.com/"},
  {name: "redux", href: "https://redux.js.org/"},
];

const displayedProjectsCount = 3;

type PageProps = {
  projects: ProjectModel[];
  totalProjectCount: number;
}

const Home: NextPage<PageProps> = (props) => {

  const router = useRouter();

  return (
    <>
      <div className="my-8 md:my-16">
        <h1 className="text-3xl md:text-5xl font-black font-special">Nik Tomazic</h1>
        <h2 className={classNames(
          "text-xl font-base font-special text-special font-bold", TEXT_GRADIENT, BLUE_GRADIENT
        )}>
          Full Stack Developer
        </h2>
      </div>
      <div className="my-8 md:my-16">
        <p className="text-sm md:text-base md:text-justify text-secondary">
          With <span className="text-default">seven years of coding</span> under my belt, I can provide {" "}
          <span className="text-default">quality code</span> in a variety of programming languages like {" "}
          <span className="text-default">Python</span>, <span className="text-default">Java</span>, {" "}
          <span className="text-default">JavaScript</span>, {" "}
          <span className="text-default">TypeScript</span>, and <span className="text-default">PHP</span>. {" "}
          I’m experienced in <span className="text-default">server administration</span>, {" "}
          <span className="text-default">web development</span>, <span className="text-default">graphic design</span>
          {" "} and <span className="text-default">technical writing</span>. I’m always trying my {" "}
          <span className="text-default">best</span> to work <span className="text-default">fast</span> and {" "}
          <span className="text-default">efficiently</span> and making things {" "}
          <span className="text-default">as perfect as possible</span>. I also like {" "}
          <span className="text-default">challenges</span>.
        </p>
      </div>
      <div className="hidden md:block my-16">
        <SectionBreak text="Skillset"/>
        <StyledMarquee>
          <div className="flex flex-row gap-x-12 ml-12">
            {technologies.map((technology, index) => (
                <a
                  key={index}
                  href={technology.href}
                  aria-label={technology.name + "'s website"}
                  target="_blank" rel="noreferrer"
                >
                  <i className={`devicon-${technology.name}-plain text-3xl md:text-5xl`}/>
                </a>
              )
            )}
          </div>
        </StyledMarquee>
      </div>
      <div className="my-16">
        <SectionBreak text="Recent projects"/>
        <ProjectList projects={props.projects.slice(0, displayedProjectsCount)}/>
        {props.totalProjectCount > displayedProjectsCount && (
          <div className="my-8 text-center">
            <Link href={"/projects"}>
              <a>
                <Button>+{props.totalProjectCount - displayedProjectsCount} More Projects</Button>
              </a>
            </Link>
          </div>
        )}
      </div>
      <div className="my-16">
        <SectionBreak text="Statistics"/>
        <div className="flex flex-col md:flex-row gap-y-4 gap-x-2">
          <div className="space-y-2 basis-1/3 text-center">
            <div className="text-sm md:text-base">My GitHub Rating</div>
            <div className={classNames("text-2xl md:text-3xl font-bold", TEXT_GRADIENT, BLUE_GRADIENT)}>A++</div>
          </div>
          <div className="space-y-2 basis-1/3 text-center">
            <div className="text-sm md:text-base">Total Stars Earned</div>
            <div className={classNames("text-2xl md:text-3xl font-bold", TEXT_GRADIENT, BLUE_GRADIENT)}>200+</div>
          </div>
          <div className="space-y-2 basis-1/3 text-center">
            <div className="text-sm md:text-base">Total Commits 2023</div>
            <div className={classNames("text-2xl md:text-3xl font-bold", TEXT_GRADIENT, BLUE_GRADIENT)}>1.5k+</div>
          </div>
        </div>
      </div>
      <div className="my-16">
        <WideButton onClick={() => router.push('/blog')}>View My Blog</WideButton>
      </div>
    </>
  )
}

export const getServerSideProps: GetServerSideProps = async (context) => {
  let data: PageProps = {
    projects: [],
    totalProjectCount: 0,
  };

  await getProjects().then(projectsData => {
    if (projectsData == null) return;
    data["projects"] = projectsData.results;
    data["totalProjectCount"] = projectsData.count;
  });

  return {props: data};
}

export default Home;
