import Image from 'next/image';

export type ProjectTechnologyListProps = {
  technologies: string[];
}

let unsupportedTechnologies = [
  "elasticsearch",
];

let technologyLinks = {
  git: "https://git-scm.com/",
  python: "https://www.python.org/",
  javascript: "https://developer.mozilla.org/en-US/docs/Web/JavaScript",
  mysql: "https://www.mysql.com/",
  docker: "https://www.docker.com/",
  django: "https://www.djangoproject.com/",
  java: "https://www.java.com/en/",
  php: "https://www.php.net/",
  lua: "https://www.lua.org/",
  react: "https://reactjs.org/",
  typescript: "https://www.typescriptlang.org/",
  postgresql: "https://www.postgresql.org/",
  nextjs: "https://nextjs.org/",
  kotlin: "https://kotlinlang.org/",
  flask: "https://flask.palletsprojects.com/en/2.2.x/",
  fastapi: "https://fastapi.tiangolo.com/",
  tailwindcss: "https://tailwindcss.com/",
  redux: "https://redux.js.org/",
  elasticsearch: "https://www.elastic.co/",
  html5: "https://en.wikipedia.org/wiki/HTML",
  css3: "https://en.wikipedia.org/wiki/CSS",
  socketio: "https://socket.io/",
  composer: "https://getcomposer.org/",
};

export default function ProjectTechnologyList(props: ProjectTechnologyListProps) {
  return (
    <div className="flex flex-wrap space-x-2">
      {props.technologies.map((technology, index) => {
        return (
          <a
            key={index}
            className="bg-background-secondary text-secondary py-1 px-2 rounded-lg w-10 h-10 flex items-center mb-2"
            // @ts-ignore
            href={technologyLinks[technology]}
            aria-label={technology + "'s website"}
            target="_blank" rel="noreferrer"
          >
            {unsupportedTechnologies.includes(technology) ? (
              <Image
                src={"/technology/" + technology + ".png"}
                alt={technology}
                width={32} height={32}
                className="grayscale"
              />
            ) : (
              <i className={`devicon-${technology}-plain text-xl`}/>
            )}
          </a>
        )
      })}
    </div>
  )
}