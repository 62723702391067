import Hr from "./Hr";
import Button from "./Button";

export type WideButtonProps = {
  children: any;
  onClick?: any;
}

export default function WideButtonProps(props: WideButtonProps) {
  return (
    <div className="flex justify-center items-center">
      <div className="hidden md:block basis-1/5"><Hr/></div>
      <div className="md:basis-3/5 text-center">
        <Button className="w-full md:w-3/4" onClick={props.onClick}>
          {props.children}
        </Button>
      </div>
      <div className="hidden md:block basis-1/5"><Hr/></div>
    </div>
  );
}