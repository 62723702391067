import ProjectListItem from "./ProjectListItem";
import ProjectModel from "../../store/models/ProjectModel";

export type ProjectListProps = {
  projects: ProjectModel[];
};

export default function ProjectList(props: ProjectListProps) {
  return (
    <div className="space-y-8">
      {props.projects.length == 0 ? (
        <>
          Oops, no projects matching your filters could be found.
        </>
      ) : (
        <>
          {props.projects.map((project, index) => {
            return <ProjectListItem project={project} key={index}/>;
          })}
        </>
      )}
    </div>
  )
}